export const assetsColumns = [
  {
    width: 130,
    headerName: "Name",
    field: "title",
  },
  {
    field: "image",
    headerName: "Image",
    width: 130,
    renderCell: function (params) {
      return (
        <div className="cellWithImage">
          <img src={params.row.image} className="cellImg" alt="Avatar" />
        </div>
      );
    },
  },
];
