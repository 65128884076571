// Notification.js
import React, { useEffect, useState } from 'react';
import './notification.scss'; // Ensure your styles are defined

const Notification = ({ message, isVisible, type }) => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    if (type === 'loading') {
      const interval = setInterval(() => {
        setDots((prev) => (prev.length < 3 ? prev + '.' : ''));
      }, 500);
      return () => clearInterval(interval);
    }
  }, [type]);

  return (
    isVisible && (
      <div className={`notification ${type}`}>
        {message}{dots}
      </div>
    )
  );
};

export default Notification;
