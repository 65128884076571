import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useReducer, createContext, useContext, useState, useEffect } from "react";
import { firebaseAuth } from "../firebase-config";
import Cookies from 'js-cookie';
import CryptoJS from "crypto-js";

const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const INITIAL_STATE = { currentuser: null };

const AuthContext = createContext(INITIAL_STATE);

export const useAuthContext = function () {
  return useContext(AuthContext);
};

const reducer = function (state, action) {
  switch (action.type) {
    case LOGIN:
      return {
        currentuser: action.payload,
      };
    case LOGOUT:
      return {
        currentuser: null,
      };
    default:
      return state;
  }
};

const encryptData = (data) => {
  const key = process.env.REACT_APP_COOKIE_ENCRYPTION_KEY; 
  return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
};

const decryptData = (cipherText) => {
  const key = process.env.REACT_APP_COOKIE_ENCRYPTION_KEY;
  const bytes = CryptoJS.AES.decrypt(cipherText, key);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

function AuthContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [error, setError] = useState(false);
  const [errMsg, seterrMsg] = useState("");

  const login = async function ({ email, password }) {
    try {
      const credentials = await signInWithEmailAndPassword(firebaseAuth, email, password);

      const userData = {
        email: credentials.user.email,
        uid: credentials.user.uid,
      };

      dispatch({
        type: LOGIN,
        payload: userData,
      });

      const encryptedUserData = encryptData(userData);
      Cookies.set("user", encryptedUserData, { 
        expires: 3, 
        secure: true,
        sameSite: "Strict",
        priority: "High"
      });

      setError(false);
      seterrMsg("");
    } catch (err) {
      setError(true);
      seterrMsg(err.message);
      console.log(err);
    }
  };

  const logout = async function () {
    try {
      await signOut(firebaseAuth);
      dispatch({ type: LOGOUT });

      Cookies.remove("user");

      setError(false);
      seterrMsg("");
    } catch (err) {
      setError(true);
      seterrMsg(err.message);
      console.log(err);
    }
  };

  useEffect(() => {
    const userCookie = Cookies.get("user");
    if (userCookie) {
      try {
        const userData = decryptData(userCookie);
        dispatch({ type: LOGIN, payload: userData });
      } catch (error) {
        console.error("Failed to decrypt cookie:", error);
      }
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentuser: state?.currentuser,
        dispatch,
        login,
        logout,
        error,
        errMsg,
        setError,
        seterrMsg,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;