import React, { useEffect, useState } from "react";
import "./card.scss";
import { collection, getDocs } from "firebase/firestore";
import { fireStore } from "../../firebase-config";
import { assetS } from "../../App";
import { useAuthContext } from "../../context/AuthContext";
function Card({ type }) {
  const { seterrMsg, setError } = useAuthContext();
  const [Count, setCount] = useState(null);
  let content = {};
  switch (type) {
    case assetS:
      content = {
        title: "Main Page",
      };
      break;
    default:
      break;
  }
  let images = content.isImage ? "$ " + content.images + " k" : Count;
  const pathType = type === assetS ? assetS : "";
  useEffect(() => {
    const getData = async function () {
      try {
        const dataQuery = collection(fireStore, pathType);
        const data = await getDocs(dataQuery);
        setCount(data.docs.length);
        setError(false);
        seterrMsg("");
      } catch (err) {
        setError(true);
        seterrMsg(err.message);
      }
    };
    pathType && getData();
  }, [pathType, setError, seterrMsg]);
  return (
    <article className="card">
          <div className="group-hero_text light">Welcome, Currently there are {images} images uploaded.</div>
        <div className="group-hero_text light"><br></br>To upload more click the second button on the left.</div>
    </article>
  );
}
export default Card;