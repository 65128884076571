import "./newform.scss";
import { useRef, useState, useEffect } from "react";
import noAvatar from "../../assets/noimage.png";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { fireStore, firebaseAuth, firebaseStorage } from "../../firebase-config";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useAuthContext } from "../../context/AuthContext";
import { addDoc, collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import 'select2/dist/css/select2.min.css';
import 'select2';

const folderOptions = [
    { id: "images/boiler/1101", label: "Boiler 1101" },
    { id: "images/boiler/1102", label: "Boiler 1102" },
    { id: "images/boiler/1103", label: "Boiler 1103" },
    { id: "images/boiler/1104", label: "Boiler 1104" },
    { id: "images/boiler/1105", label: "Boiler 1105" },
    { id: "images/boiler/1106", label: "Boiler 1106" },
    { id: "images/oiler/101", label: "Oiler 101" },
    { id: "images/oiler/103", label: "Oiler 103" },
    { id: "images/oiler/104", label: "Oiler 104" },
    { id: "images/pressure/105", label: "Pressure 105" },
    { id: "images/rodrig/001", label: "Rod Rig 001" },
    { id: "images/rodrig/002", label: "Rod Rig 002" },
    { id: "images/300s/301", label: "Doghouse 301" },
    { id: "images/300s/302", label: "Doghouse 302" },
    { id: "images/servicerig/003", label: "Service Rig 03" },
    { id: "images/servicerig/004", label: "Service Rig 04" },
    { id: "images/tmx/201", label: "TMX 201" },
    { id: "images/tmx/202", label: "TMX 202" },
    { id: "images/tmx/501", label: "TMX 501" },
    { id: "images/tmx/701", label: "TMX 701" },
    { id: "images/tmx/801", label: "TMX 801" },
    { id: "images/turnarounds/905", label: "Turnaround 905" },
    { id: "images/turnarounds/zet", label: "Turnaround Zero Entry Tool" },
    { id: "images/vac/902", label: "Vac 902" },
    { id: "images/vac/903", label: "Vac 903" },
    { id: "images/vac/904", label: "Vac 904" },
    { id: "images/vac/906", label: "Vac 906" },
    { id: "images/vac/1001", label: "Vac 1001" },
    { id: "images/vac/1002", label: "Vac 1002" },
    { id: "images/vac/1003", label: "Vac 1003" },
    { id: "images/vac/1004", label: "Vac 1004" },
    { id: "images/vac/1005", label: "Vac 1005" },
    { id: "images/vac/1006", label: "Vac 1006" },
    { id: "images/water/106", label: "Water 106" },
    { id: "images/water/109", label: "Water 109" },
    { id: "images/water/908", label: "Water 908" },
  ];

function NewItem({ dataSource, path = "users" }) {
  const navigate = useNavigate();
  const { seterrMsg, setError, error, errMsg } = useAuthContext();

  const inputRef = useRef([]);
  const [imageFile, setimageFile] = useState(null);
  const [percent, setPercent] = useState(null);
  const [imageURL, setimageURL] = useState(null);
  const [uploadTime, setUploadTime] = useState(null);
  const [folderPaths, setFolderPaths] = useState([]); 

  const validImageTypes = ["image/png", "image/jpeg", "image/webp"];

  const chooseImageHandler = (file) => {
    if (file && validImageTypes.includes(file.type)) {
      setimageFile(file);
      setimageURL(URL.createObjectURL(file)); 
    } else {
      alert("Please upload a valid image file (PNG, JPG, WEBP).");
    }
  };

  const addInputRef = (input) => {
    if (input && !inputRef.current.includes(input)) {
      inputRef.current.push(input);
    }
  };

  const uploadImage = async () => {
    if (!imageFile || folderPaths.length === 0) return;

    const uploadPromises = folderPaths.map(async (folderPath) => {
      const storageRef = ref(firebaseStorage, `${folderPath}/${imageFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, imageFile);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(2);
            setPercent(progress);
            console.log("Upload is " + progress + "% done");
          },
          (error) => {
            console.error("Upload error:", error);
            reject(error); 
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setimageURL(downloadURL);
              setUploadTime(new Date().toISOString());
              resolve(downloadURL); 
            });
          }
        );
      });
    });

    return Promise.all(uploadPromises); 
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const areFilled = inputRef.current.every((elt) => Boolean(elt.value));
    if (!areFilled || !imageFile) return;

    const uploadedImageURLs = await uploadImage();

    const newData = { image: uploadedImageURLs, uploadTime, folderPaths };
    inputRef.current.forEach((elt) => {
      newData[elt.id] = elt.value;
    });

    try {
      if (path === "users") {
        const credentials = await createUserWithEmailAndPassword(
          firebaseAuth,
          newData.email,
          newData.password
        );
        const userRef = doc(fireStore, path, credentials.user.uid);
        await setDoc(userRef, { ...newData, timestamp: serverTimestamp(), filePath: uploadedImageURLs.join(', ') });
      } else {
        await addDoc(collection(fireStore, path), {
          ...newData,
          timestamp: serverTimestamp(),
          filePath: uploadedImageURLs.join(', '),
        });
      }

      setError(false);
      seterrMsg("");
      navigate(`/${path}`);
    } catch (err) {
      setError(true);
      seterrMsg(err.message);
      console.log(err);
    }
  };

  const removeImageHandler = () => {
    setPercent(null);
    setimageURL(null);
    setimageFile(null);
  };

  const handleFolderSelection = (e) => {
    const selectedValues = $(e.target).val();
    setFolderPaths(selectedValues); 
  };

  useEffect(() => {
    const folderSelect = $('#folder-select');
    if (folderSelect.length) {
      folderSelect.select2({
        placeholder: "Select folders",
        allowClear: true,
        width: "100%",
      });
      folderSelect.on("change", handleFolderSelection);
    }

    return () => {
      if (folderSelect.length) {
        folderSelect.select2("destroy");
        folderSelect.off("change", handleFolderSelection);
      }
    };
  }, []);

  return (
    
    <div className="home">
      <div className="navigation">
        <h1>Add New Item</h1>
        <div className="navigation-right">
          <div className="user-wrap">
            <div className="user">
              <div className="user-info">
                <div className="user-name">Logged In</div>
                <div className="user-description">Admin User</div>
              </div>
              <img src="https://brooksfieldserviceltd.com/assets/images/site-images/favicon.png" loading="lazy" alt="User Icon" className="user-icon" />
            </div>
          </div>
        </div>
      </div>
      <div className="new">
        <div className="content">
          <div className="left">
            <img
              src={imageURL || (imageFile ? URL.createObjectURL(imageFile) : noAvatar)}
              alt="avatar"
            />
            {imageFile && (
              <button
                className="remove-button"
                onClick={removeImageHandler}
                style={{ cursor: "pointer" }}
              >
                Remove Attachment
              </button>
            )}
          </div>
          <div className="right">
            {error && <div className="error">{errMsg}</div>}
            <form onSubmit={submitHandler}>
  <div className="inputItem file">
    <p>{percent ? `Uploading ${percent}%` : ""}</p>
    {percent === null && (
      <>
        <label htmlFor="file" className="remove-button center">
          <span>Click Here To Select an Image</span>
        </label>
        <input
          type="file"
          onChange={(e) => chooseImageHandler(e.currentTarget.files[0])}
          id="file"
          style={{ display: "none" }}
        />
      </>
    )}
  </div>

  <div className="inputItem">
    <label>Select Folders</label>
    <select id="folder-select" multiple="multiple">
      {folderOptions.map((folder) => (
        <option key={folder.id} value={folder.id}>
          {folder.label}
        </option>
      ))}
    </select>
  </div>

  {dataSource.map((data) => (
    <div className="inputItem" key={data.id}>
      <label htmlFor={data.id}>{data.label}</label>
      <input
        id={data.id}
        name={data.id}
        type={data.type}
        placeholder={data.placeholder}
        ref={addInputRef}
        required={data?.required}
        {...data}
      />
    </div>
  ))}

  {percent === null || percent === 100 ? (
    <button
      className="button"
      disabled={!imageFile}
      style={{ cursor: "pointer" }}
    >
      Click To Upload
    </button>
  ) : null}
</form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewItem;
