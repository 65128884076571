import "./datatable.scss";
import { assetsColumns } from "../../data/usersData";
import { fireStore } from "../../firebase-config";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { useAuthContext } from "../../context/AuthContext";
import Notification from './notification'; 

import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, deleteDoc, doc, getDoc, onSnapshot } from "firebase/firestore";

function DataTable({ path = "assets" }) { 
  const { error, setError, errMsg, seterrMsg } = useAuthContext();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [notification, setNotification] = useState({ message: '', isVisible: false, type: '' });

  const storage = getStorage(); 

  const showNotification = (message, type) => {
    setNotification({ message, isVisible: true, type });
    setTimeout(() => {
      setNotification(prev => ({ ...prev, isVisible: false }));
    }, 3000);
  };

  const handleDelete = async (id) => {
    try {
      showNotification("Deleting Files...", "loading");
      const docRef = doc(fireStore, path, id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const filePath = docSnap.data().filePath;

        if (filePath) {
          const filePaths = filePath.split(', ').map(p => p.trim());
          const filesToDelete = filePaths.map(path => {
            const basePath = path.substring(0, path.lastIndexOf('.'));
            return [
              path,
              `${basePath}_640x640.webp`, 
              `${basePath}_1024x1024.webp`
            ];
          }).flat();

          for (const file of filesToDelete) {
            const fileRef = ref(storage, file);
            await deleteObject(fileRef)
              .then(() => {
                console.log("File deleted from Storage:", file);
                showNotification("File Deleted", "error");
              })
              .catch((error) => {
                console.error("Error deleting file:", file, error);
              });
          }
        }

        await deleteDoc(docRef);
        setError(false);
        seterrMsg("");
      } else {
        console.log("No such document!");
      }
    } catch (err) {
      setError(true);
      seterrMsg(err.message);
      console.log("Error deleting document or file:", err);
    }
  };

  const action = [
    {
      field: "action",
      headerName: "Delete",
      width: 80,
      renderCell: function (params) {
        return (
          <div className="cellAction">
            <div
              className="actionBtnDelete"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  const extraColumns = [
    {
      field: "timestamp",
      headerName: "Upload Date",
      width: 200,
      valueGetter: (params) => {
        const timestamp = params.row.timestamp;
        if (timestamp && typeof timestamp.toDate === "function") {
          const date = timestamp.toDate();
          return date.toLocaleString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            month: "numeric",
            day: "numeric",
            year: "numeric",
          });
        }
        return "No Date";
      },
    },
    {
      field: "folderPath",
      headerName: "File Location",
      width: 300,
      valueGetter: (params) => {
        const folderPaths = params.row.folderPaths;
  
        if (folderPaths && Array.isArray(folderPaths)) {
          const pathEndings = folderPaths.map(path => path.split('/').pop());
          return pathEndings.join(', ');
        }
        return "No Folder";
      },
    },
  ];
  

  useEffect(() => {
    const unsub = onSnapshot(collection(fireStore, path), (snap) => {
      const newData = snap.docs.map((doc) => {
        const data = doc.data();
        console.log("Document Data:", data);
        return { ...data, id: doc.id };
      });

      setData(newData);
      setFilteredData(newData);
      if (newData.length > data.length) {
        showNotification("Files Updated", "success");
      }
    });

    return unsub;
  }, [path, data.length]);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = data.filter(
      (item) =>
        item.title?.toLowerCase().includes(value) ||
        (item.folderPaths && item.folderPaths[0]?.toLowerCase().includes(value))
    );
    setFilteredData(filtered);
  };

  const dataSource = assetsColumns; 
  const add = "Upload"; 

  return (
    <div className="home">
      <Notification message={notification.message} isVisible={notification.isVisible} type={notification.type} />
      <div className="navigation">
        <h1>Assets</h1>
        <div className="navigation-right">
          <div className="user-wrap">
            <div className="user">
              <div className="user-info">
                <div className="user-name">Logged In</div>
                <div className="user-description">Admin User</div>
              </div>
              <img src="https://brooksfieldserviceltd.com/assets/images/site-images/favicon.png" loading="lazy" alt="User Icon" className="user-icon" />
            </div>
          </div>
        </div>
      </div>
      <article className="card">
        <div className="group-hero">
          <div className="group-hero_l">
            <div className="group-hero_text light">Click "Upload" to Upload Images.</div>
          </div>
          <Link to={`/${path}/new`}>
            <div className="button w-inline-block">
              <div className="embed-icon w-embed">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1695_120)">
                    <path d="M8.00021 14.9623C11.8453 14.9623 14.9623 11.8453 14.9623 8.00021C14.9623 4.15514 11.8453 1.03809 8.00021 1.03809C4.15514 1.03809 1.03809 4.15514 1.03809 8.00021C1.03809 11.8453 4.15514 14.9623 8.00021 14.9623Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
                    <path d="M5.09961 8H10.9014" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8 5.09961V10.9014" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1695_120">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div>{add}</div>
            </div>
          </Link>
        </div>
      </article>
      <div className="DataTable">
        {error && <h3>{errMsg}</h3>}
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search by unit or title"
          className="search-input"
        />
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={filteredData}
            columns={dataSource.concat(extraColumns, action)}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      </div>
    </div>
  );
}

export default DataTable;
