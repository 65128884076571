import "./sidebar.scss";
import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";

function SideBar() {
  const { currentuser, logout } = useAuthContext();
  const [hoveredButton, setHoveredButton] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 991);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 991);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogout = async () => {
    await logout();
  };

  return (
    <aside className={`sidebar`}>
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous"></link>
      <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap" rel="stylesheet"></link>
      <div className="nav-top">
        {!isMobile && (
          <a href="/" className="logo-link w-inline-block">
            <img src="https://brooksfieldserviceltd.com/newassets/images/site-images/bfslogo-removebg-500.webp" loading="lazy" width="210" alt="Logo" />
          </a>
        )}
        <div className="nav-separator"></div>
        <div className="nav-menu">
          <ul className="center">
            <NavLink
              to={"/"}
              onMouseEnter={() => setHoveredButton('home')}
              onMouseLeave={() => setHoveredButton(null)}
              className={({ isActive }) => `${isActive || hoveredButton === 'home' ? "activeClassName" : ""}`}
            >
              <li>
                <div className="icon">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1692_35)">
                      <path d="M6.56113 0.805664H0.805664V6.56113H6.56113V0.805664Z"
                        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round" />
                      <path d="M15.1949 0.805664H9.43945V6.56113H15.1949V0.805664Z"
                        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round" />
                      <path d="M6.56113 9.43945H0.805664V15.1949H6.56113V9.43945Z"
                        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round" />
                      <path d="M15.1949 9.43945H9.43945V15.1949H15.1949V9.43945Z"
                        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1692_35">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                {!isMobile && <span className="nav-link_text">Home</span>}
              </li>
            </NavLink>
            <NavLink
              to={"/assets"}
              onMouseEnter={() => setHoveredButton('assets')}
              onMouseLeave={() => setHoveredButton(null)}
              className={({ isActive }) => `${isActive || hoveredButton === 'assets' ? "activeClassName" : ""}`}
            >
              <li>
                <div className="icon">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1692_52)">
                      <path d="M1.07715 11.9648L8.00023 16.0033L14.9233 11.9648" stroke="currentColor"
                        strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M1.07715 8.50391L8.00023 12.5424L14.9233 8.50391" stroke="currentColor"
                        strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path
                        d="M1.07715 5.04237L8.00023 9.08083L14.9233 5.04237L8.00023 1.00391L1.07715 5.04237Z"
                        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1692_52">
                        <rect width="16" height="16.7273" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                {!isMobile && <span className="nav-link_text">Assets</span>}
              </li>
            </NavLink>
          </ul>
        </div>
      </div>
      <div className="nav-bot">
        {currentuser && (
          <ul className="center">
            <NavLink
              to={"/login"}
              onClick={handleLogout}
              onMouseEnter={() => setHoveredButton('logout')}
              onMouseLeave={() => setHoveredButton(null)}
              className={({ isActive }) => `${isActive || hoveredButton === 'logout' ? "activeClassName" : ""}`}
            >
              <li>
                <div className="icon w-embed">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1692_84)">
                      <path fillRule="evenodd" clipRule="evenodd"
                        d="M0.0078125 0.0078125H8.49266V1.21993H1.21993V14.5533H8.49266V15.7654H0.0078125V0.0078125ZM11.9516 3.82169L15.9996 7.8698L11.9683 12.237L11.0776 11.415L13.7751 8.49266H3.64418V7.28054H13.6962L11.0944 4.67878L11.9516 3.82169Z"
                        fill="currentColor" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1692_84">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                {!isMobile && <span className="nav-link_text">Logout</span>}
              </li>
            </NavLink>
          </ul>
        )}
      </div>
    </aside>
  );
}

export default SideBar;
